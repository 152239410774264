@font-face {
  font-family: mako;
  src: url("./assets/Mako-Regular-webfont.ttf");
}

body {
  margin: 0;
  padding: 0;
  font-family: mako, "Helvetica Neue", Helvetica, Arial, "Lantinghei SC",
    "Microsoft YaHei", "Hiragino Sans GB", "STHeiti", "WenQuanYi Micro Hei",
    SimSun, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

pre {
  background: #000 !important;
  padding: 5px;
  overflow: auto;
}

pre code {
  color: #11dbe4;
  background: #000;
}

code {
  font-family: consolas;
  font-size: 13px;
  background: #f1f1f1;
  color: #f34908;
}

img {
  max-width: 100%;
}

video {
  max-width: 100%;
  max-height: 100%;
}

.video-wrap {
  text-align: center;
}

.bigeditor .bf-content {
  height: calc(100vh - 320px) !important;
  font-size: 13px;
}

ol,
ul {
  padding: 0px 16px !important;
}

p {
  min-height: unset !important;
  margin: 5px !important;
}

.w-e-text-container {
  height: 100px !important;
}

.w-e-text code {
  background: #000;
}

.w-e-text {
  background: #f7f7f7;
}

.w-e-toolbar {
  border: none !important;
}

.w-e-text-container {
  border: none !important;
}

iframe {
  width: 100%;
}

iframe[bilibili] {
  height: 542px;
}

::-webkit-scrollbar {
  width: 2px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: hsla(0, 0%, 39.2%, 0.5);
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

@keyframes zd {
  0% {
    transform: rotate(15deg) scale(1);
  }

  10% {
    transform: rotate(-15deg) scale(1);
    color: red;
  }

  20% {
    transform: rotate(15deg) scale(1);
  }

  30% {
    transform: rotate(-15deg) scale(1);
    color: blue;
  }

  40% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
    color: orange;
  }
}
